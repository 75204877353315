import $ from "jquery";
import * as bootstrap from "bootstrap";
import * as storage from 'idb-keyval';

import "./jquery_hack";

$.fn.extend({
    transitionIn: function () {
        return this.each(function () {
            $(this).css("opacity", 100).css("visibility", "visible");
        });
    },
    transitionOut: function () {
        return this.each(function () {
            $(this).css("opacity", 0).css("visibility", "hidden");
        });
    },
});

$(async () => {
    const unavailableFeatures: Array<string> = [];
    if (!("serviceWorker" in navigator)) {
        unavailableFeatures.push("serviceWorker");
    }
    if (!("PushManager" in window)) {
        unavailableFeatures.push("Push API");
    }
    if (!("Notification" in window)) {
        unavailableFeatures.push("Notification");
    }
    if (!("indexedDB" in window)) {
        unavailableFeatures.push("indexedDB");
    }

    let agent_type: string;
    if (navigator.userAgent.includes("iPhone")) {
        agent_type = "iPhone";
    } else if (navigator.userAgent.includes("iPad")) {
        agent_type = "iPad";
    } else if (navigator.userAgent.includes("iPod touch")) {
        agent_type = "iPod touch";
    } else {
        agent_type = "mobile";
    }

    if (unavailableFeatures.length > 0) {
        $("#ios-type").text(agent_type);
        $("#ios-instructions").transitionIn().siblings().transitionOut();

        throw new Error(
            `This page requires features that your browser doesn't have! Missing features: ${unavailableFeatures.join(", ")}`
        );
    }

    await navigator.serviceWorker.register(new URL("service.ts", import.meta.url), {
        type: "module",
    });

    navigator.serviceWorker.addEventListener("message", (ev) => {
        console.log("Client got message:", ev.type, ev.data);

        if (ev.type === "message") {
            if (ev.data.type === "error") {
                show_error(ev.data.error);
            } else if (ev.data.type === "push") {
                const $el = $(`
                    <div class="alert alert-light alert-dismissible fade show" role="alert">
                        ${ev.data.push_notification.title}
                        <hr>
                        ${ev.data.push_notification.body}
                        <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                    </div>
                `);
                window.setTimeout(() => bootstrap.Alert.getOrCreateInstance($el[0]).close(), 10000);
                $("#alert-wrapper").prepend($el);
            }
        }
    });

    document.addEventListener("visibilitychange", () => {
        // TODO: do some self checks here maybe?
        if (document.visibilityState === "visible") {
        } else if (document.visibilityState === "hidden") {
        }
    });

    const user_name = await storage.get("user_name") as string | undefined;
    if (user_name) {
        const sw = await navigator.serviceWorker.getRegistration();
        sw?.active?.postMessage({ type: "login", name: user_name });

        $("#button-page").transitionIn().siblings().transitionOut();
    } else {
        $("#name-page").transitionIn().siblings().transitionOut();
    }

    $("#signup-form").on("submit", async (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        const form = ev.target;
        const name = $(form).find("#name").val();
        if (!name) {
            throw new Error("Name is empty");
        }

        const permission = await window.Notification.requestPermission();
        if (permission !== "granted") {
            show_error("Notification permissions are necessary to run the site!");
        }

        const user_name = name.toString();
        await storage.set("user_name", user_name);

        const sw = await navigator.serviceWorker.getRegistration();
        sw?.active?.postMessage({ type: "login", name: user_name });

        $("#button-page").transitionIn().siblings().transitionOut();
    });

    $("#push").on("click", async () => {
        const sw = await navigator.serviceWorker.getRegistration();
        sw?.active?.postMessage({ type: "push-button" });
    });
});

function show_error(msg: string) {
    $("#alert-wrapper").prepend(`
        <div class="alert alert-danger alert-dismissible fade show" role="alert">
            ${msg}
            <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
        </div>
    `);
    throw new Error(msg);
}
